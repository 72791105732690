<template>
  <div>
    <div class="">
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">{{ type == 1 ? '新增' : '编辑' }}进口量</div>
        <div class="erp-form-bar">
          <div class="erp-primary__btn" @click="submit">保存</div>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="erp-form__body">
        <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
          <div class="mali-edit__form">
            <el-row :gutter="16" type="flex">
              <el-col :span="12">
                <el-form-item label="进口月份" prop="importDate">
                  <el-date-picker
                    format="YYYY-MM"
                    v-model="formData.importDate"
                    prefix-icon="null"
                    placeholder="请选择进口月份"
                    style="width: 100%"
                    type="month"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="中国牛肉月进口量(MT)" prop="beefImport">
                  <el-input
                    :maxlength="7"
                    v-model="formData.beefImport"
                    clearable
                    placeholder="请输入中国牛肉月进口量"
                    class="mali-full__inputcom"
                    @blur="changeAmount($event, 'beefImport')"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { formatTime } from '@/utils/publicMethods';
export default {
  props: {
    type: { type: [String, Number] },
    baseData: { type: [Object] }, // 基础信息

    version: { type: [String, Number] },
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      rules: {
        // 检验规则
        importDate: [
          {
            required: true,
            message: '请选择进口月份',
            trigger: 'change',
          },
        ],

        beefImport: [
          {
            required: true,
            message: '请输入中国牛肉月进口量',
            trigger: 'blur',
          },
        ],
      },
      formData: {
        beefImport: null,
        id: null,
        importDate: null,
        version: null,
      },
    };
  },
  created() {
    if (this.type === 2) {
      this.getDetailInfo();
    }
  },
  methods: {
    getDetailInfo() {
      // 获取查看审核详情
      const { beefImport, id, importDate, version } = this.baseData;
      this.formData.importDate = importDate;
      this.formData.beefImport = beefImport;
      this.formData.id = id;
      this.formData.version = version;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        let params = JSON.parse(JSON.stringify(this.formData));
        params.importDate = formatTime(params.importDate, 'YYYY-MM');
        if (valid) {
          this.ajax({
            type: 'POST',
            url: '/malicrm/macro/operateMacroImport',
            data: params,
            success: (res) => {
              if (res.code === 200) {
                this.successTip('保存成功');
                this.$emit('cancel', true);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        }
      });
    },
    changeAmount(e, name) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
      if (Number(value) === 0) {
        value = null;
      }
      this.formData[name] = value ? Number(value) : null;
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
