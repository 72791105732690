import { httpPost } from '@/api';
import { ErpTableOptions } from '@/types/type';
import { erpConfirm, errorTip, successTip } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '指数日期',
      prop: 'statisticsDate',
      propDesc: 'statisticsDate',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['statisticsDateStart', 'statisticsDateEnd'],
    },
    {
      label: '总价格指数(rmb/kg)',
      prop: 'totalIndex',
      propDesc: 'totalIndexDesc',
      minWidth: 200,
      value: [],
      type: 'number',
      filterProp: ['totalIndexMin', 'totalIndexMax'],
    },
    {
      label: '巴西价格指数',
      prop: 'brazilIndex',
      propDesc: 'brazilIndexDesc',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['brazilIndexMin', 'brazilIndexMax'],
    },
    {
      label: '澳洲价格指数',
      prop: 'australiaIndex',
      propDesc: 'australiaIndexDesc',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['australiaIndexMin', 'australiaIndexMax'],
    },
    {
      label: '乌拉圭价格指数',
      minWidth: 170,
      prop: 'uruguayIndex',
      propDesc: 'uruguayIndexDesc',
      value: [],
      type: 'number',
      filterProp: ['uruguayIndexMin', 'uruguayIndexMax'],
    },
    {
      label: '阿根廷价格指数',
      prop: 'argentinaIndex', // actualWeightSu
      propDesc: 'argentinaIndexDesc',
      minWidth: 170,
      value: [],
      type: 'number',
      filterProp: ['argentinaIndexMin', 'argentinaIndexMax'],
    },
    {
      label: '进口月份',
      prop: 'importDate',
      propDesc: 'importDate',
      minWidth: 170,
      value: [],
      type: 'time',
      filterProp: ['importDateStart', 'importDateEnd'],
    },
    {
      label: '中国牛肉月进口量(MT)',
      prop: 'beefImport',
      propDesc: 'beefImportDesc',

      minWidth: 220,
      type: 'number',
      filterProp: ['beefImportMin', 'beefImportMax'],
      value: [],
    },
    {
      label: '操作时间',
      prop: 'updateTime',
      propDesc: 'updateTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['updateTimeStart', 'updateTimeEnd'],
    },
    {
      label: '操作人',
      prop: 'lastOperator',
      propDesc: 'lastOperator',
      minWidth: 140,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 164,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button type="primary" plain  v-if="hasPermission('erp:macro:operateMacroIndex')" @click='addContract(row)'>编辑</el-button>
        <el-button plain v-if="hasPermission('erp:macro:operateMacroIndex')" @click='deleteItem(row)'>删除</el-button>
          `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const addContract = (row) => {
            Instance.setupState.getauditDetails(row);
          };
          const deleteItem = (row) => {
            erpConfirm('确定删除该条记录吗？')
              .then(async () => {
                const res = await httpPost('/malicrm/macro/deleteMacro', {
                  id: row.id,
                });
                if (res.code === 200) {
                  successTip('删除成功');
                  Instance.setupState.refreshTable();
                } else {
                  errorTip(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { addContract, deleteItem };
        },
      }),
    },
  ];
  return { tableRef, columnList };
};
