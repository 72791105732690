<template>
  <div>
    <div>
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">{{ type == 1 ? '新增' : '编辑' }}指数</div>
        <div class="erp-form-bar">
          <div class="erp-primary__btn" @click="submit">保存</div>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="erp-form__body">
        <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
          <div class="mali-edit__form">
            <el-row :gutter="16" type="flex">
              <el-col :span="12">
                <el-form-item label="指数日期" prop="statisticsDate">
                  <el-date-picker
                    value-format="YYYY-MM-DD"
                    v-model="formData.statisticsDate"
                    prefix-icon="null"
                    placeholder="请选择指数日期"
                    type="date"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="总价格指数(rmb/kg)" prop="totalIndex">
                  <el-input
                    :maxlength="5"
                    v-model="formData.totalIndex"
                    clearable
                    placeholder="请输入总价格指数"
                    class="mali-full__inputcom"
                    @blur="changeAmount($event, 'totalIndex')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="巴西价格指数(rmb/kg)" prop="brazilIndex">
                  <el-input
                    :maxlength="5"
                    v-model="formData.brazilIndex"
                    clearable
                    placeholder="请输入巴西价格指数"
                    class="mali-full__inputcom"
                    @blur="changeAmount($event, 'brazilIndex')"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="澳洲价格指数(rmb/kg)" prop="australiaIndex">
                  <el-input
                    :maxlength="5"
                    v-model="formData.australiaIndex"
                    clearable
                    placeholder="请输入澳洲价格指数"
                    class="mali-full__inputcom"
                    @blur="changeAmount($event, 'australiaIndex')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="乌拉圭价格指数(rmb/kg)" prop="uruguayIndex">
                  <el-input
                    :maxlength="5"
                    v-model="formData.uruguayIndex"
                    clearable
                    placeholder="请输入乌拉圭价格指数"
                    class="mali-full__inputcom"
                    @blur="changeAmount($event, 'uruguayIndex')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="阿根廷价格指数(rmb/kg)" prop="argentinaIndex">
                  <el-input
                    :maxlength="5"
                    v-model="formData.argentinaIndex"
                    clearable
                    placeholder="请输入阿根廷价格指数"
                    class="mali-full__inputcom"
                    @blur="changeAmount($event, 'argentinaIndex')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { formatTime } from '@/utils/publicMethods';
export default {
  props: {
    type: { type: [String, Number] },
    baseData: { type: [Object] }, // 基础信息

    version: { type: [String, Number] },
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      rules: {
        // 检验规则
        statisticsDate: [
          {
            required: true,
            message: '请选择指数日期',
            trigger: 'change',
          },
        ],

        totalIndex: [
          {
            required: true,
            message: '请输入总价格指数',
            trigger: 'blur',
          },
        ],
        uruguayIndex: [
          {
            required: true,
            message: '请输入乌拉圭价格指数',
            trigger: 'blur',
          },
        ],
        brazilIndex: [
          {
            required: true,
            message: '请输入巴西价格指数',
            trigger: 'blur',
          },
        ],
        australiaIndex: [
          {
            required: true,
            message: '请输入澳洲价格指数',
            trigger: 'blur',
          },
        ],
        argentinaIndex: [
          {
            required: true,
            message: '请输入阿根廷价格指数',
            trigger: 'blur',
          },
        ],
      },
      formData: {
        argentinaIndex: null,
        australiaIndex: null,
        brazilIndex: null,
        id: null,
        statisticsDate: null,
        totalIndex: null,
        uruguayIndex: null,
        version: null,
      },
    };
  },
  created() {
    if (this.type === 2) {
      this.getDetailInfo();
    }
  },
  methods: {
    getDetailInfo() {
      // 获取查看审核详情
      const { argentinaIndex, australiaIndex, brazilIndex, id, statisticsDate, totalIndex, uruguayIndex, version } = this.baseData;
      this.formData.brazilIndex = brazilIndex;
      this.formData.argentinaIndex = argentinaIndex;
      this.formData.australiaIndex = australiaIndex;
      this.formData.id = id;
      this.formData.statisticsDate = statisticsDate;
      this.formData.totalIndex = totalIndex;
      this.formData.uruguayIndex = uruguayIndex;
      this.formData.version = version;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.formData));
          params.statisticsDate = formatTime(params.statisticsDate, 'YYYY-MM-DD');
          this.ajax({
            type: 'POST',
            url: '/malicrm/macro/operateMacroIndex',
            data: params,
            success: (res) => {
              if (res.code === 200) {
                this.successTip('保存成功');
                this.$emit('cancel', true);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        } else {
          this.errorTip('请填写所有带星号的必填项');
        }
      });
    },

    changeAmount(e, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 1000) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 3);
        }
      }
      if (Number(value) === 0) {
        value = null;
      }
      this.formData[name] = value ? Number(value) : null;
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
