import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_beefIndexForm = _resolveComponent("beefIndexForm")!
  const _component_newImport = _resolveComponent("newImport")!
  const _component_erp_drawer = _resolveComponent("erp-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/macro/queryMacroIndexListWithPage",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "beefIndex",
      onRowClick: _ctx.rowClick
    }, {
      header: _withCtx(() => [
        (_ctx.hasPermission('erp:macro:operateMacroIndex'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "erp-primary__btn ml-8",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addIndex(1, 1)))
            }, " 新增指数 "))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:macro:operateMacroIndex'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "erp-primary__btn ml-8",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addIndex(2, 1)))
            }, " 新增进口量 "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columOptions", "onRowClick"]),
    _createVNode(_component_erp_drawer, {
      visible: _ctx.dialog.visible,
      onClose: _ctx.closeDialog,
      erp: "",
      width: 960,
      class: "no-padding fix-top-header"
    }, {
      default: _withCtx(() => [
        (_ctx.dialog.type === 1)
          ? (_openBlock(), _createBlock(_component_beefIndexForm, {
              key: 0,
              type: _ctx.dialog.formType,
              onCancel: _ctx.closeDialog,
              baseData: _ctx.detailsData
            }, null, 8, ["type", "onCancel", "baseData"]))
          : _createCommentVNode("", true),
        (_ctx.dialog.type === 2)
          ? (_openBlock(), _createBlock(_component_newImport, {
              key: 1,
              type: _ctx.dialog.formType,
              onCancel: _ctx.closeDialog,
              baseData: _ctx.detailsData
            }, null, 8, ["type", "onCancel", "baseData"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onClose"])
  ], 64))
}